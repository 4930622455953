@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400..700&family=Poppins:wght@400;600&display=swap');

:root {
  --primary-color: #3d5a80;
  --secondary-color: #ee6c4d;
  --text-color: #000;
  --white: #fff;
  --light-bg: #e0fbfc;
  --dark-bg: #293241;
  --gray: #8799b0;
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Lora', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

h2 {
  color: var(--primary-color);
}

body {
  font-family: var(--font-primary);
  line-height: 1.6;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
}

.hero-section {
  background-image: url('/public/hero-img.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.hero-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 41px 80px 103px;
}

.hero-wrapper {
  display: flex;
  margin-bottom: -21px;
  width: 100%;
  max-width: 940px;
  flex-direction: column;
  align-items: start;
}

.header {
  border-radius: 8px;
  background-color: var(--white);
  align-self: stretch;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 16px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
}

.logo-wrapper {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  margin: auto 0;
}

.logo {
  object-fit: contain;
  object-position: center;
  width: 127px;
  max-width: 100%;
}

.nav-wrapper {
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  /* min-width: 240px; */
  gap: 30px;
  margin: auto 0;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  color: var(--text-color);
  font-weight: 400;
}

.nav-item {
  align-self: stretch;
  white-space: nowrap;
  margin: auto 0;
  padding: 8px;
  text-decoration: none;
  color: inherit;
}

.login-btn {
  align-self: stretch;
  border-radius: 8px;
  background-color: var(--primary-color);
  min-height: 36px;
  font-size: 12px;
  color: var(--white);
  font-weight: 600;
  margin: auto 0;
  padding: 9px 16px;
  border: none;
  cursor: pointer;
}

.hero-title {
  color: var(--white);
  margin-top: 218px;
  width: 592px;
  font: 600 54px/60px var(--font-primary);
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.hero-title span {
  font-weight: 100;
}

.hero-subtitle-wrapper {
  display: flex;
  margin-top: 24px;
  width: 513px;
  max-width: 100%;
  flex-direction: column;
}

.hero-subtitle {
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
}

.cta-button {
  align-self: stretch;
  border-radius: 8px;
  background-color: var(--secondary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  width: 401px;
  max-width: 100%;
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.mission-section {
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  padding: 112px 80px;
}

.mission-wrapper {
  display: flex;
  margin-bottom: -22px;
  width: 780px;
  max-width: 100%;
  flex-direction: column;
}

.mission-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.mission-title {
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  align-self: center;
}

.mission-description {
  color: var(--primary-color);
  font-size: 22px;
  margin-top: 16px;
}

.mission-details,
.support-details {
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  font-family: var(--font-secondary);
  color: var(--text-color);
}

.mission-paragraph,
.support-paragraph {
  font-size: 18px;
}

.mission-explanation,
.support-explanation {
  font-size: 16px;
  margin-top: 10px;
}

.mission-cta {
  width: 100%;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 20px;
  color: var(--text-color);
  font-weight: 700;
  text-align: center;
  padding: 20px 28px;
  border: 1px solid var(--gray);
}

.benefits-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 33px 70px 0;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,239,246,1) 100%);
  margin-top: 80px;
}

.benefits-wrapper {
  z-index: 10;
  margin-bottom: -50px;
  width: 100%;
  max-width: 900px;
}

.benefits-content {
  gap: 20px;
  display: flex;
}

.benefit-column,
.engagement-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0;
}

.benefit-text,
.engagement-text {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  justify-content: center;
  margin: 88px -30px 0 0;
}

.benefit-title,
.engagement-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 46px;
}

.benefit-description,
.engagement-description {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

.benefit-image-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}

.benefit-image {
  object-fit: contain;
  object-position: center;
  width: 100%;
  flex-grow: 1;
}

.how-it-works-section {
  background-color: var(--white);
  display: flex;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
}

.how-it-works-title {
  width: 940px;
  max-width: 100%;
  color: var(--primary-color);
  text-align: center;
  padding: 0 70px;
  font: 500 42px/1 var(--font-primary);
}

.how-it-works-content {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  padding: 40px 0;
}

.step-wrapper,
.step-2-wrapper,
.step-3-wrapper,
.step-4-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: -10px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 210px;
}

.step-content,
.step-2-content,
.step-3-content,
.step-4-content {
  border-radius: 8px;
  background-color: #212834;
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: var(--white);
  width: 300px;
  margin: auto 0;
  padding: 20px 40px 24px 20px;
}

.step-header,
.step-2-header,
.step-3-header,
.step-4-header {
  display: flex;
  width: 100%;
  padding-right: 48px;
  flex-direction: column;
  align-items: start;
}

.step-number,
.step-2-number,
.step-3-number,
.step-4-number {
  font-size: 16px;
  font-weight: 400;
}

.step-title,
.step-2-title,
.step-3-title,
.step-4-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  width: 240px;
}

.step-description,
.step-2-description,
.step-3-description,
.step-4-description {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

.campaign-preview {
  border-radius: 8px;
  background-color: var(--primary-color);
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  width: 453px;
  margin: auto 0;
  margin-left: -20px;
}

.campaign-content {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 24px;
}

.campaign-avatar {
  object-fit: contain;
  object-position: center;
  width: 71px;
  border-radius: 50%;
}

.campaign-details {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  flex: 1;
  flex-basis: 0%;
}

.campaign-header {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.campaign-title {
  color: var(--light-bg);
  text-align: center;
  font: 700 20px var(--font-primary);
}

.campaign-social {
  display: flex;
  margin-top: 8px;
  align-items: center;
  gap: 20px;
}

.social-icon {
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.campaign-description {
  color: var(--white);
  margin-top: 24px;
  font: 400 16px var(--font-primary);
}

.campaign-cta {
  align-self: center;
  border-radius: 4px;
  background-color: var(--light-bg);
  margin-top: 24px;
  min-height: 39px;
  max-width: 100%;
  width: 421px;
  color: var(--dark-bg);
  padding: 8px 12px;
  font: 600 16px var(--font-primary);
  border: none;
  cursor: pointer;
}

.step-1-image,
.step-2-image,
.step-3-image,
.step-4-image {
  object-fit: contain;
  object-position: center;
  width: 460px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  align-self: stretch;
  min-width: 240px;
  margin: auto 0;
  margin-left: -30px;
}

.partner-cta {
  border-radius: 8px;
  background-color: var(--secondary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 425px;
  max-width: 100%;
  color: var(--white);
  padding: 12px;
  margin-top: 40px;
  font: 600 16px var(--font-primary);
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  align-self: center;
}

.section-divider {
  align-self: center;
  margin-top: 22px;
  width: 340px;
  max-width: 100%;
  height: 1px;
  background-color: var(--gray);
  opacity: 0.3;
}

.section-divider.mobile {
  display: none;
}

.trust-section {
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: var(--text-color);
  justify-content: start;
  padding: 120px 0;
}

.trust-title {
  width: 940px;
  max-width: 100%;
  text-align: center;
  padding: 0 70px;
  font: 500 42px/1 var(--font-primary);
}

.trust-content {
  display: flex;
  margin-top: 80px;
  width: 940px;
  max-width: 100%;
  flex-direction: column;
  font-weight: 400;
}

.trust-item {
  padding-bottom: 20px;
}

.trust-icon {
  width: 24px;
}

.trust-item-title {
  display: flex;
  gap: 8px;
  font-family: var(--font-primary);
  align-self: start;
}

.trust-item-description {
  font-family: var(--font-secondary);
  margin-top: 12px;
}

.contact-section {
  background-color: #3e4553;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-primary);
  color: var(--white);
  justify-content: start;
  padding: 120px 0;
}

.contact-title {
  width: 940px;
  max-width: 100%;
  font-size: 42px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  padding: 0 70px;
}

.contact-content {
  display: flex;
  margin-top: 60px;
  width: 940px;
  max-width: 100%;
  align-items: start;
  gap: 40px 100px;
  font-weight: 400;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  border-radius: 0;
  min-width: 240px;
  font-size: 16px;
  width: 266px;
}

.contact-form {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-size: 16px;
  width: 572px;
}

.form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-label {
  align-self: start;
}

.form-input {
  border-radius: 6px;
  background-color: #3e4553;
  display: flex;
  margin-top: 8px;
  height: 42px;
  border: 1px solid var(--text-color);
}

.form-textarea {
  border-radius: 6px;
  background-color: #3e4553;
  display: flex;
  margin-top: 8px;
  height: 112px;
  border: 1px solid var(--text-color);
}

.form-submit {
  align-self: stretch;
  border-radius: 8px;
  background-color: var(--secondary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  width: 100%;
  color: var(--white);
  font-weight: 600;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.footer {
  background-color: var(--dark-bg);
  display: flex;
  width: 100%;
  padding-top: 60px;
  flex-direction: column;
  position: relative;
}

.footer-content {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 940px;
  gap: 20px;
  font-family: var(--font-primary);
  color: var(--white);
  justify-content: space-between;
  align-items: center;
}

.footer-logo-symbol {
  position: absolute;
  width: 300px;
  bottom: 70px;
  opacity: 0.7;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  z-index: 99;
}

.footer-logo {
  object-fit: contain;
  object-position: center;
  width: 145px;
  max-width: 100%;
}

.footer-tagline {
  margin-top: 31px;
}

.footer-links {
  align-self: start;
  display: flex;
  gap: 40px 49px;
  font-size: 14px;
  font-weight: 400;
}

.footer-link-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.footer-link {
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;
}

.footer-bottom {
  background-color: var(--gray);
  display: flex;
  margin-top: 60px;
  min-height: 60px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.footer-wrapper {
  width: 940px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-copyright {
  color: var(--text-color);
  align-self: stretch;
  margin: auto 0;
  font: 400 12px var(--font-primary);
}

.footer-social {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: start;
  margin: auto 0;
}

.social-icon-footer {
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}


/* INTERNAL */
.internal-header-section {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(229,239,246,1) 100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.internal-header-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px 0 60px;
}

.internal-header-wrapper {
  display: flex;
  width: 100%;
  max-width: 940px;
  flex-direction: column;
  align-items: start;
}

.internal-header-title {
  color: var(--primary-color);
  width: 100%;
  text-align: center;
  padding: 40px;
  font: 600 32px/44px var(--font-primary);
}

.terms-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 100;
}

.terms-content {
  display: flex;
  width: 940px;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.terms-txt {
  width: 100%;
}



@media (max-width: 991px) {
  .hero-content {
      padding: 41px 30px 40px;
  }

  .hero-wrapper {
      max-width: 100%;
      margin-bottom: 10px;
  }

  .header {
      max-width: 100%;
      padding: 8px;
  }

  .logo {
    width: 110px;
  }

  .hero-title {
      max-width: 100%;
      margin-top: 90px;
      font-size: 40px;
      line-height: 49px;
  }

  .hero-subtitle-wrapper {
      max-width: 100%;
  }

  .hero-subtitle {
    font-size: 19px;
  }

  .mission-section {
      max-width: 100%;
      padding: 100px 30px;
  }

  .mission-wrapper {
      max-width: 100%;
      margin-bottom: 10px;
  }

  .mission-title {
    text-align: left;
    font-size: 33px;
    line-height: 43px;
  }

  .benefits-section {
      max-width: 100%;
      padding: 33px 40px 0;
      padding-bottom: 40px;
  }

  .benefits-wrapper {
      max-width: 100%;
      margin-bottom: 10px;
  }

  .benefits-content {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
  }

  .benefit-column,
  .engagement-column,
  .benefit-image-column {
      width: 100%;
      margin-left: 0;
  }

  .benefit-text,
  .engagement-text {
      margin-top: 40px;
      width:100%;
  }

  .section-divider.mobile {
    display: block;
  }

  .how-it-works-section {
      max-width: 100%;
      padding-top: 100px;
  }

  .how-it-works-content {
    padding: 0 30px;
}

  .how-it-works-title {
      padding: 0 20px;
  }

  .step-wrapper,
  .step-2-wrapper,
  .step-3-wrapper,
  .step-4-wrapper {
      padding: 40px 0;
  }

  .step-content,
  .step-2-content,
  .step-3-content,
  .step-4-content {
    padding: 20px;
    padding-bottom: 40px;
  }

  .step-1-image,
  .step-2-image,
  .step-3-image,
  .step-4-image {
      max-width: 100%;
      margin-top: -20px;
      margin-left: 0;
  }

  .partner-cta {
      margin-top: 40px;
  }

  .trust-section {
      max-width: 100%;
      padding: 100px 30px;
      z-index: 99;
  }

  .trust-title,
  .trust-content {
      max-width: 100%;
      padding: 0;
  }

  .trust-item-title {
    font-size: 18px;
  }

  .contact-section {
      max-width: 100%;
      padding: 100px 20px;
  }

  .contact-title,
  .contact-content {
      max-width: 100%;
      padding: 0;
  }

  .footer {
      max-width: 100%;
      padding-top: 50px;
  }
  
  .footer-logo-symbol {
      z-index: 100;
  }

  .footer-content {
      max-width: 100%;
      padding: 0 30px;
      gap: 50px;
  }

  .footer-bottom {
      max-width: 100%;
      margin-top: 40px;
      padding: 20px;
  }

/* INTERNAL */
  .terms-content {
    width: 100%;
    padding: 40px 20px;
  }

  .internal-header-content {
    padding: 40px 30px 0 30px;
  }

  .nav-links {
    gap: 10px;
    font-size: 12px;
  }
}

@media (max-width: 660px) {

  .footer-content {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      padding: 0 30px;
      gap: 50px;
      z-index: 150;
  }

  .footer-bottom {
      max-width: 100%;
      margin-top: 40px;
      padding: 20px;
  }

}


/* COMING SOON */

.coming-soon-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,239,246,1) 100%);
}

.coming-soon-wrapper {
  width: 980px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coming-soon-logo {
  width: 200px;
  margin-bottom: 20px;
}

.coming-soon-page h1 {
  margin-top: 50px;
  font-size: 40px;
  line-height: 45px;
  color: var(--primary-color);
}

.coming-soon-page p {
  font-size: 1.5rem;
  color: #555;
}

@media (max-width: 991px) {
  .coming-soon-wrapper {
    width: 100%;
    padding: 0 20px;
  }
}

/* COMING SOON END */


/* PASSWORD PROTECTION */

.password-protect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.password-protect h2 {
  margin-bottom: 20px;
}

.password-protect form {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.password-protect input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 100%;
}

.password-protect button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.password-protect button:hover {
  background-color: #0056b3;
}


/* PASSWORD PROTECTION END */
